import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import Trbm from "@tightrope/trbm"
import RbMimic from "@tightrope/redbmimic"

const trbmBrowsers = ['chrome','firefox','other'];
const rbBrowsers = ['ie', 'edge'];
const homepageData = import("./data.json");

export default function prdbest() {
  return(
    <HomepageLayout>
      <Helmet><title>Breaking News Tab - breakingnewstab.com</title>
      <style type="text/css">{`
        body {
          background-color: #9e9e9e;
        }
      `}</style>
      </Helmet>
      <Trbm data={homepageData} browsers={trbmBrowsers}></Trbm>
      <RbMimic data={homepageData} browsers={rbBrowsers}></RbMimic>
    </HomepageLayout>
  )
}
